@font-face {
  font-family: 'Baloo2-r';
  src: url('../public/font/static/Baloo2-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo2-b';
  src: url('../public/font/static/Baloo2-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo2-m';
  src: url('../public/font/static/Baloo2-Medium.ttf') format('truetype');
}
button{
  padding:0 !important;
}
button a{
  text-decoration: none !important;
  padding:0 1.5rem !important;
  line-height: 55px;
  display: block;
  float: left;
  width: 100%;
}
select{
  border: 0;
}
button a.white{
  color:#fff !important;
  text-decoration: none;
}
body {
  margin: 0;
  font-family: Baloo2-r, serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn{
  font-weight: 600 !important;
}
.text-blue{
  color: #3163D4;
}
img{
  max-width: 100%;
  height: auto;
}
.text-underline{
  position: relative;
}
.text-underline .text{
  position: relative;
  z-index: 2;
  color:#3163D4;
}
.underline {
  content: "";
  position: absolute;
  bottom: 22%;
  left: 0;
  width: 100%;
  height: 19px;
  background-color: #F99E21;
}
button{
  height: 55px;
  border-radius: 10px !important;
}
.btn-light{
  border:3px solid #313E4F !important;
  color:#313E4F;
}
h1{
  font-size: 70px !important;
  line-height: 76;
  font-family: 'Baloo2-m', serif;
  letter-spacing: -3px;
}
h3{
  font-size: 60px !important;
  text-align:center;
  font-family: 'Baloo2-m', serif;
}
.group_text_banner{
  margin: 40px 0 60px;
}
/* ---- NAV ---- */
.navigation .form-select {
  max-width: 60px;
  outline: none;
  border:0;
  padding: 0 5px;
  color:#3163D4;
}
.nav-link:hover{
  color:#3163D4 !important;
  cursor: pointer;
}
.nav-link.active{
  color:#3163D4 !important;
}
.nav-link {
  font-size:18px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ---- BLOCK 1 ---- */
.block_1{
  min-height: 800px;
  background-image: url("./images/banner.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.group_text_banner p{
  font-size: 30px;
}

/* ---- BLOCK 2 ---- */

.block_2{
  background: #EDF5FF;
}
.block_2 img{
  width: 100px;
  height: 100px;
}
.block_2 .title{
  font-size: 32px;
  color: #3163D4;
  font-family: 'Baloo2-b', serif;
}
.block_2 .content{
  font-size: 20px;
  min-height: 90px;
}

/* ---- BLOCK 3 ---- */
.block_3 li{
  margin: 10px 0;
}
.block_3 .title{
  font-size: 42px;
  color: #3163D4;
  font-family: 'Baloo2-b', serif;
}
.block_3 .content{
  font-size: 22px;
  line-height: 27px;
}
.block_3_contact{
  background: #EEF2F7;
  border-radius: 30px;
}
.block_3_contact p{
  font-size: 22px;
  max-width: 80%;
  margin: 0 auto 50px;
}
@media screen and (min-width: 768px){
  .block_3_item > .col-12:nth-child(even) .col-md-6:nth-child(1){
    order:1
  }
}

/* ---- BLOCK 4 ---- */
.block_4 img{
  width: 100px;
  max-width: auto;
  height: 100px;
}
.tab-container {
  display: flex;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.slide {
  transform: translateX(-100%);
}

.tab-content {
  width: 100%;
  height: 300px;
  background-color: #f1f1f1;
  padding: 20px;
}

/* ---- BLOCK 5 ---- */

.block_5 .tab2 p{
  font-size: 32px;
}
.block_5 .service .btn{
  border:3px solid #3163D4;
  background: transparent;
  color: #3163D4;
  transition: all ease .3s;

}
.s-box{
  border: 3px solid #94A3B8;
  border-radius: 30px;
  padding: 20px;
  transition: all ease .3s;
}
.s-list-box{
  padding: 15px 0;
  text-align: center;
  border-bottom: 1px solid #94A3B8;
}
.s-list-box:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
.s-list-box span,.small_text{
  font-size: 16px;
}
.s-list-box span{
  font-weight: 400 !important;
}
.s-list-box>div{
  font-size: 26px;
  font-weight: 600;
}
.s-list-box>div>p,.s-list-box>div li{
  font-size:20px;
}
.note{
  font-size: 24px;
  line-height: 30px;
}
.price{
  font-size: 40px !important;
  color:#3163D4;
}
.sw-text{
  font-size: 30px;
}
.sw-text.active{
  color: #3163D4;
}
.s-title{
  font-size: 30px;
  color: #313E4F;
  text-align: center;
  margin-bottom: 25px;
}
.service{
  transition: all ease .3s;
}

.tab1 .col-md-3:nth-child(3) .price{
  color: #fff !important;
}
.tab1 .col-md-3:nth-child(3) .s-box{
  background: #3163D4;
  color: #fff !important;
  border-color: #3163D4;
}
.tab1 .col-md-3:nth-child(3) a{
  color: #fff;
}
.block_5 button a{
  width: 100%;
  float: left;
  padding:0;
}
.tab1 .col-md-3:nth-child(3) .s-title{
  color: #3163D4;
}
.tab1 .col-md-3:nth-child(3) .btn{
  background: #3163D4;
  color: #fff;
}
@media screen and (min-width: 768px){
  .s-list-box:nth-child(7),.s-list-box:nth-child(8),.s-list-box:last-child{
    min-height: 270px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .s-list-box:last-child{
    min-height: 190px;
  }
  .s-list-box:nth-child(7) div,.s-list-box:nth-child(8) div,.s-list-box:last-child div{
    width: 100%;
    justify-content: center;
  }
}

/* ---- BLOCK 6 ---- */
.block_6{
  background-image: url('./images/block_6_img_3.png');
  background-repeat: no-repeat;
  background-position: center right;

}

/* ---- BLOCK 7 ---- */
.f-title{
  font-size: 50px;
}
.block_7 p{
  font-size: 22px;
}
.link{
  display: flex;
  align-items: center;
}
.link a{
  color: #313E4F;
  text-decoration: none;
}
.footer{
  border-top: 1px solid #94A3B8;
  padding-top: 30px;
  margin-top: 20px;
}
.footer span{
  font-size: 20px;
}
#footer img{
  max-width:120px;
  float: left;
}
.captcha_in {
  width: 150px !important;
  float: left;
  margin-left: 10px;
}
.modal-header{
  min-height: 80px;
  background-image: url("./images/modal.png");
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  display: flex;
  justify-content: center !important;
  border-radius: 30px 30px 0 0 !important;
  border:0 !important;
}
.modal-body,.modal-footer{
  border:0 !important;
}
.modal-body p{
  font-size: 18px;
}
.modal1{
  margin-top: 20px;
}
.modal-content{
  border-radius: 30px !important;
}

#footer > div{
  margin-bottom: 15px;
}
.navigation,.navigation .col-4,.navigation .col-8,.navigation .row,.navigation .col-6,.navbar.navbar-expand-lg.navbar-light{
  position: initial;
}
@media screen and (min-width: 1025px){
  .modal-content{
    width: 600px !important;
  }
}
@media screen and (max-width: 1024px){

  .menu-mobile{
    order:2;
    padding: 0 !important;
  }
  .navbar.navbar-expand-lg.navbar-light{
  justify-content: end;
     }
  .navbar.navbar-expand-lg.navbar-light button{
    height: 30px;
    width: 30px;
    padding: 0;
    border-color: #3163D4 !important;
  }
  .navbar.navbar-expand-lg.navbar-light button span{
    width: 15px;
    height: 15px;
    color: #3163D4;
  }
  .navbar-collapse {
    position: absolute;
    top: 100%;
    background: rgb(255, 255, 255);
    width: 100%;
    left: 0;
    padding: 0 15px;
  }
  .lan{
    padding: 0 !important;
  }
  .navigation-box{
    position: relative !important;
  }
}
@media screen and (max-width:768px){
  .underline{
    height: 9px;
    bottom: 27%;
  }
  .group_text_banner{
    margin: 20px 0 40px;
  }
  h1,h3 {
    font-size: 40px !important;
  }
  .block_2 .title{
    font-size: 28px;
  }
  .block_3 .title{
    font-size: 30px;
  }
  .block_3_item-img{
    order:2;
  }
  .sw-text{
    font-size: 18px;
  }
  .block_3_item-info{
    order:1;
    margin-bottom: 15px;
  }
  .f-title{
    font-size: 36px;
  }
  .block_3_contact p{
    max-width: 100%;
  }
  .footer .col-12:first-child{
    order: 1;
    text-align: center;
    margin-top: 10px;
  }
  .block_4 img{
    max-width: 70px;
  }
  .block_1 .group_btn{
    justify-content: space-between;
  }
  .block_1 .group_btn button{
    width: 48%;
  }
}